import React, {useEffect} from 'react';
import ReactApexChart from 'react-apexcharts';

export const PieDonutChart = ({ data, labels, colors, legendOptions, total_app, dispatchBy, height, pieWidth, title, paid_app }) => {

    useEffect(() => {
        if(dispatchBy != 'app_status_billing') {
            setTimeout(() => {
                let parentContainer = document.querySelector('.apexcharts-legend.apexcharts-align-left.apx-legend-position-bottom');
                if (parentContainer) {
                    const legendItems = parentContainer.querySelectorAll('.apexcharts-legend-series');

                    if (legendItems.length > 0) {
                        const firstLegendItem = legendItems[0];
                        console.log(firstLegendItem);
                        console.log(paid_app);
                        const legendText = firstLegendItem.querySelector('.apexcharts-legend-text');
                        if (legendText) {
                            const floatEndSpan = legendText.querySelector('.float-end');
                            if (floatEndSpan) {
                                floatEndSpan.innerText = paid_app;
                            } else {
                                console.error("Float-end span element not found");
                            }
                        } else {
                            console.error("Legend text element not found");
                        }
                    } else {
                        console.error("Legend items not found");
                    }
                } else {
                    console.error("Parent container not found");
                }
            }, 2000);
        }
    }, []);


    const chartData = {
        series: data,
        options: {
            plotOptions: {
                pie: {
                    donut: {
                        size: `${pieWidth}%`,
                    },
                },
            },
            labels: labels,
            colors: colors,
            dataLabels: {
                enabled: false,
                formatter: function (val, opts) {
                    return opts.w.globals.series[opts.seriesIndex];
                },
                background: {
                    enabled: true,
                    borderRadius: 4,
                },
                dropShadow: {
                    enabled: true,
                    color: '#000',
                    top: 1,
                    left: 1,
                    blur: 2,
                },
            },
            legend: {
                show: true,
                formatter: function (val, opts) {
                    const seriesIndex = opts.seriesIndex;
                    const label = labels[seriesIndex];
                    const value = opts.w.globals.series[seriesIndex];
                    return `${label}: <span class="float-end">${value}</span> <hr class="w-100">`
                },
                position: 'bottom',
                horizontalAlign: 'left',
                floating: false,
                fontSize: '12px',
                width: '100%',
                display: 'grid',
                top: 0,
                marginTop: 0,
                offsetX: 0,
                offsetY: legendOptions?.topY || 0,
                labels: {
                    colors: ['#333'],
                },
                markers: {
                    width: 12,
                    height: 12,
                    radius: 6,
                },
            },
            chart: {
                height: 'auto'
            },
        },
    };

    let myStyle = {};
    if(dispatchBy === "payment_status"){
        myStyle = {position:"absolute", fontSize:26,width:32,height: 24, top: 60, left: 135, fontWeight:600}
    }else if(dispatchBy === "app_status_billing"){
        myStyle = {position:"absolute", fontSize:20,width:100,height: 35, top: 120, left: 102, fontWeight:600}
    }else {
       myStyle = {position:"absolute", fontSize:29,width:34,height: 26, top: 110, left: 132, fontWeight:600}
    }
    const totalAppCount = total_app < 10 ? `0${total_app}` : total_app;

    return (
        <div className="wrapper p-0">
            <h5 className={`text-theme-active fs-8 text-center ${dispatchBy === "app_status" ? "mt-3 mb-3" : ""}`}>{title}</h5>
            <div className="d-flex flex-column position-relative justify-content-center flex-row-fluid p-0 mb-0">
                <p className={`totalAppCount text-center ${dispatchBy === "app_status" ? "appStatusCount" : "paymentStatusCount"}`} style={myStyle}>{totalAppCount}</p>
                <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="donut"
                    className="dashboard-app-stats"
                    width={'100%'}
                    height={height}
                />
            </div>
        </div>
    );
};

