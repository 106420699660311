import {KTSVG} from "../../general/KTSVG";
import {NotFound} from "../../general/NotFound";
import React, {useContext} from "react";
import {formatDateDMY} from "../../general/utils";
import {BillingContext} from "../context/BillingContext";
import Swal from "sweetalert2";

export const BillingUserList = ({lists}) => {
    const { handleEditBillingSubUser, removeBillingSubUser } = useContext(BillingContext);

    const handleRemoveBillingSubUser = async (item) => {
        const confirmationMessage = await Swal.fire({
            title: "Are you sure?",
            text: `Remove ${item?.full_name}`,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: '#1E6FE0',
            cancelButtonColor: '#dc3545',
            confirmButtonText: "Yes, Remove!",
            cancelButtonText: "Cancel",
            dangerMode: true,
        });
        let payload = {
            user_id: item?.user_id,
            action: 'delete_sub_user',
            email: item?.email,
            parent_id: item?.parent_id
        };
        if(confirmationMessage.isConfirmed){
           removeBillingSubUser(payload);
        }
    };

    return (
        <div className="billing-details-container mt-5">
            <div className="row mt-4 group-provider-section">
                <div  className={`col-12`}>
                    <div className="card bg-transparent rounded-5 border-top-0">
                        <div
                            className="card-header card-header-style py-0 m-0 d-flex justify-content-between fw-semibold fs-8 headerBgImg text-uppercase align-middle align-items-center">
                            <h4 className="text-white pt-2 fs-5 text-uppercase ps-3">User List</h4>
                            <div className="btn" onClick={ () => handleEditBillingSubUser(null, 'add')}><span
                                className="text-primary f-w500"><KTSVG path="/svg/plusbtn.svg"/> </span></div>
                        </div>
                        <div className="card-body">
                            <div className="financeStatement financeInvoice mt-10 table-responsive">
                                <table
                                    id="kt_datatable_zero_configuration"
                                    className="table table-row-bordered gy-5 table-hover invoiceTbl"
                                >
                                    <thead>
                                    <tr className="fw-semibold fs-8 text-white text-center headerBgImg1 bg-gray text-uppercase align-middle align-items-center">
                                        <th>User Name</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody className="fs-8 text-center bgGray">
                                    {lists?.length === 0 || lists === null ? (
                                        <tr>
                                            <td colSpan="7">
                                                <NotFound message="No Account Statement Available"/>
                                            </td>
                                        </tr>
                                    ) : (
                                        lists?.map((list, index) => (
                                            <tr
                                                key={index}
                                                className="align-middle align-items-center data-table-row bg-gray cursor-pointer"
                                                title="User Details"
                                            >
                                                <td>{list?.full_name}</td>
                                                <td>{list?.email}</td>
                                                <td>{list?.phone_number}</td>
                                                <td>{formatDateDMY(list?.added_on)}</td>
                                                <td>
                                                    <button className="btn btn-primary btn-sm" onClick={ () => handleEditBillingSubUser(list, 'edit')}><i className="fa fa-pencil" /></button>
                                                    <button className="btn btn-danger btn-sm ms-2" onClick={ () => handleRemoveBillingSubUser(list, 'delete')}><i className="fa fa-trash" /></button>

                                                </td>
                                            </tr>
                                        ))
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
