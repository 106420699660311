import axios from "../../../axios_handler";
const {REACT_APP_BACKEND_API_URL} = process.env;
const GET_USER_DASHBOARD_URL = REACT_APP_BACKEND_API_URL+"get_user_dashboard_info"
const UPDATE_PERSONAL_INFO_URL = REACT_APP_BACKEND_API_URL+"update_personal_info"

let getUserDashboardReq = async () => {
    try {
        const d = await axios
            .get(`${GET_USER_DASHBOARD_URL}`);
        return d.data;
    } catch (error) {
        console.error("Error in User Dashboard Req() : ", error);
        throw error;
    }
}
let updatePersonalInfoReq = async (data) => {
    try {
        const d = await axios
            .post(`${UPDATE_PERSONAL_INFO_URL}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in update Persona lInfo Req() : ", error);
        throw error;
    }
}

let getBillingDashboardReq = async (location ) => {
    try {
        const d = await axios
            .get(`${REACT_APP_BACKEND_API_URL}dashboard_billing_customer_portal`, {params: {location: location ? location : null}});
        return d.data;
    } catch (error) {
        console.error("Error in Billing Dashboard Req() : ", error);
        throw error;
    }
}

const getBillingTopInsuranceReq = async () => {
    try {
        const d = await axios
            .get(`${REACT_APP_BACKEND_API_URL}dashboard_billing_top_paid_insurance`);
        return d.data;
    } catch (error) {
        console.error("Error in getBillingTopInsuranceReq() : ", error);
        throw error;
    }
}
const getSelectedMonthBillingDashboardReq = async (month, year) => {
    try {
        const d = await axios
            .get(`${REACT_APP_BACKEND_API_URL}dashboard_get_billing_data_selected_month/${month}/${year}`);
        return d.data;
    } catch (error) {
        console.error("Error in getSelectedMonthBillingDashboardReq() : ", error);
        throw error;
    }
}

const getPaymentByFilterDateReq = async (start_date, end_date) => {
    try {
        const d = await axios
            .get(`${REACT_APP_BACKEND_API_URL}dashboard_get_payment_by_filter_date`, {params: {start_date, end_date}});
        return d.data;
    } catch (error) {
        console.error("Error in getPaymentByFilterDateReq() : ", error);
        throw error;
    }
}

export {getUserDashboardReq, updatePersonalInfoReq, getBillingDashboardReq, getBillingTopInsuranceReq, getSelectedMonthBillingDashboardReq, getPaymentByFilterDateReq}