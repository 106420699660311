import React, {useState, useEffect, useContext} from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import LogoutButton from "../LogoutButton";
import './Layout.css';
import {KTSVG} from "../general/KTSVG";
import {AuthContext} from "../login/context/AuthContext";
const Sidebar = ({show}) => {
    let {user} = useContext(AuthContext);
    const location = useLocation();
    const [url, setUrl] = useState(null);
    const {app_id, credential_id} = useParams();
    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);
console.log("user", user)
    return (
        <div className={` ${show ? 'd-block' : 'd-none'} d-lg-block sidebar sidebarWrapper`}>
            <div className="position-sticky">
                <div className="list-group list-group-flush me-3 mt-4">
                    <div>
                        <img alt='Logo' src={process.env.PUBLIC_URL + '/logo.png'} className='p-3 d-none d-lg-block'/>
                    </div>
                    <ul className="list-group list-group-flush">
                        {user?.customer_type === 'billing' ? (
                            <>
                                <li className={"list-group-item py-1 mt-4 pb-2 px-4" + (url === "/dashboard-billing" ? " nav-active" : "")}
                                    title="Billing Dashboard">
                                    <KTSVG path='/svg/bar-chart.svg' className='fs-3 me-4 text-success fw-bold'/>
                                    <Link className="text-reset" to="/dashboard-billing">Dashboard</Link>
                                </li>
                                <li className={"list-group-item py-1 mt-4 pb-2 px-4" + (url === "/add-billing-details" ? " nav-active" : "")}
                                    title="Add Billing Details">
                                    <KTSVG path='/svg/users.svg' className='fs-3 me-4 text-success fw-bold'/>
                                    <Link to="/add-billing-details" className="text-reset">Add Details</Link>
                                </li>
                                {(!user?.is_child_user && !user?.child_id) && (


                                <li className={"list-group-item py-1 mt-4 pb-2 px-4" + (url === "/add-billing-user" ? " nav-active" : "")}
                                    title="Add Users">
                                    <KTSVG path='/svg/adduser.svg' className='fs-3 me-4 text-success fw-bold'/>
                                    <Link to="/add-billing-user" className="text-reset">Add User</Link>
                                </li>
                                )}
                                {/*<li className={"list-group-item py-1 mt-4 pb-2 px-4" + (url === "/billing-report" ? " nav-active" : "")}*/}
                                {/*    title="Billing Report">*/}
                                {/*    <KTSVG path='/svg/report.svg' className='fs-3 me-4 text-success fw-bold'/>*/}
                                {/*    <Link to="/billing-report" className="text-reset">Billing Report</Link>*/}
                                {/*</li>*/}
                            </>
                        ) : (
                            <>
                                <li className={"list-group-item py-1 mt-4 pb-2 px-4" + (url === "/dashboard" ? " nav-active" : "")}>
                                    <KTSVG path='/svg/bar-chart.svg' className='fs-3 me-4 text-success fw-bold'/>
                                    <Link className="text-reset" to="/dashboard">Dashboard</Link>
                                </li>
                                <li className={"list-group-item py-1 mt-4 pb-2 px-4" + ((url === "/user-details" || url === "/user-details/add" || credential_id) ? " nav-active" : "")}>
                                    <KTSVG path='/svg/users.svg' className='fs-3 me-4 text-success fw-bold'/>
                                    <Link to="/user-details" className="text-reset">Add Details</Link>
                                </li>
                                <li className={"list-group-item py-1 mt-4 pb-2 px-4" + ((url === "/application" || app_id) ? " nav-active" : "")}>
                                    <KTSVG path='/svg/data-table.svg' className='fs-3 me-4 text-success fw-bold'/>
                                    <Link to="/application" className="text-reset">Applications</Link>
                                </li>
                                <li className={"list-group-item py-1 mt-4 pb-2 px-4" + ((url === "/documents") ? " nav-active" : "")}>
                                    <KTSVG path='/svg/books.svg' className='fs-3 me-4 text-success fw-bold'/>
                                    <Link to="/documents" className="text-reset">Contract</Link>
                                </li>
                                <li className={"list-group-item py-1 mt-4 pb-2 px-4" + ((url === "/invoice") ? " nav-active" : "")}>
                                    <KTSVG path='/svg/invoices.svg' className='fs-3 me-4 text-success fw-bold'/>
                                    <Link to="/invoice" className="text-reset">Invoice</Link>
                                </li>
                            </>
                        )}
                        {(!user?.is_child_user && !user?.child_id) && (
                        <li className={"list-group-item py-1 mt-4 pb-2 px-4" + ((url === "/profile") ? " nav-active" : "")}>
                            <KTSVG path='/svg/profile.svg' className='fs-3 me-4 text-success fw-bold'/>
                            <Link to="/profile" className="text-reset">Profile</Link>
                        </li>
                            )}
                    </ul>
                </div>
            </div>
            <div className="footerBtm ms-2">
                <LogoutButton/>
            </div>
        </div>
    );
};

export default Sidebar;
