import React, {useContext, useEffect, useState} from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import {BillingContext} from "../context/BillingContext";
import {AuthContext} from "../../login/context/AuthContext";

export const AddBillingUser = () => {
    const { addBillingUser, isLoading, formContextForUser } = useContext(BillingContext);
    let {user} = useContext(AuthContext);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        full_name: "",
        phone_number: "",
        email: "",
        password: ""
    });

    useEffect(() => {
        if (formContextForUser) {
            setFormData(formContextForUser);
        }
    }, [formContextForUser]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const validateForm = () => {
        let newErrors = {};
        if (!formData.full_name) newErrors.full_name = "Name is required";
        if (!formData.phone_number) newErrors.phone_number = "Phone is required";
        if (!formData.email) newErrors.email = "Email is required";
        if (!formData.password) newErrors.password = "Password is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        let payload = { ...formData };

        if (formData.user_id) {
            // Only send the required fields for sub user updating
            payload = {
                user_id: formData.user_id,
                parent_id: formData.parent_id,
                full_name: formData.full_name,
                email: formData.email,
                phone_number: formData.phone_number,
                action: 'update_sub_user'
            };
        }else{
            payload.action = 'create_sub_user'
            payload.parent_id = user?.user_id
        }
        addBillingUser(payload);

    };
    return (
        <div className="container-fluid">
            <div className="row my-4">
                <div className={`col-12 ${isLoading ? 'position-relative' : ''}`}>
                    <div
                        className="card-header card-header-style py-0 m-0 d-flex justify-content-between fw-semibold fs-8 headerBgImg text-uppercase align-middle align-items-center">
                        <Row>  <Col md={12}><h5 className="header-text text-white pt-xl-3 pt-lg-2 pb-xl-1 pb-lg-0 ps-4">Add User</h5></Col></Row>
                    </div>
                    <div className="card rounded-bottom-5 bgGray border-top-0 border-top-right-0 border-top-left-0 bg-gray w-100 p-3 ps-4">
                        <div className="card-body">
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="full_name"
                                                placeholder="Enter name"
                                                value={formData.full_name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.full_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.full_name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control
                                                type="tel"
                                                name="phone_number"
                                                placeholder="Enter phone number"
                                                value={formData.phone_number}
                                                onChange={handleChange}
                                                isInvalid={!!errors.phone_number}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.phone_number}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                placeholder="Enter email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                isInvalid={!!errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    {formContextForUser == null && (
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                    name="password"
                                                placeholder="Enter password"
                                                value={formData.password}
                                                onChange={handleChange}
                                                isInvalid={!!errors.password}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.password}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Control type="hidden" name="parent_user_id" value={formData.id} />
                                    </Col>
                                    )}
                                </Row>
                                <div className="text-end">
                                    <Button type="submit" variant="primary" disabled={isLoading}>
                                        {isLoading ? "Saving..." : "Save"}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};