import {createContext, useState} from "react"
import {saveLoginDetailsReq, getBillingDetailReq, removeBillingDetailReq, updateBillingLoginReq, addProviderDetailReq, updateProviderDetailReq, updateBillingPracticeDetailReq, addBillingUserReq, getBillingUserListReq, removeBillingSubUserReq} from "./_request"
import {customAlert} from "../../general/helpers";
import Swal from "sweetalert2";
export const BillingContext = createContext()
export const BillingContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [billingDetail, setBillingDetail] = useState({});
    const [billingLists, setBillingLists] = useState({});
    const [formContext, setFormContext] = useState(null);
    const [formContextForUser, setFormContextForUser] = useState(null);
    const [formName, setFormName] = useState(null);
    const [formNameForUser, setFormNameForUser] = useState(null);
    const [billingUserList, setBillingUserList] = useState([]);

    const saveLoginDetails = async (data) => {
        setLoading(true);
        try {
            const response = await saveLoginDetailsReq(data);
            if (response?.status?.toLowerCase() === 'success') {
                customAlert(response.msg, 'success');
                backHome();
                await getBillingDetail();
                return true;
            } else {
                customAlert(response?.message, 'error');
                return false;
            }
        } catch (error) {
            console.error('Error adding billing detail:', error);
            return false;
        } finally {
            setLoading(false);
        }
    };
    const getBillingDetail = async () => {
        setLoading(true);
        try {
            const response = await getBillingDetailReq();
            if (response.status.toLowerCase() === 'success' && Array.isArray(response.result) && response.result.length > 0) {
                setBillingDetail(response.result);
                const documentList = response?.document_lists || [];
                const loginList = response?.login_lists || [];
                const providerList = response?.provider_lists || [];
                const practiceList = response?.practice_lists || [];
                const practiceLocationList = response?.practice_locations || [];
                setBillingLists({documentList, loginList, providerList, practiceList, practiceLocationList});
            } else {
                setBillingDetail({});
            }
        } catch (error) {
            console.error('Error getting billing detail:', error);
            setBillingDetail({});
        } finally {
            setLoading(false);
        }
    };

    const editBillingDetail = (item, dispatch) => {
        setLoading(true);
        setFormContext(item)
        setFormName(dispatch)
        setLoading(false);
    }

    const backHome = (backToModule=null) => {
        if(backToModule == 'addUser'){
            setFormContextForUser(null);
            setFormNameForUser(null);
        }else{
            setFormContext(null);
            setFormName(null);
        }

    }
    const removeBillingDetail = async (id, action, showAlert=true) => {
        let formData = new FormData();
        formData.append('id', id);
        formData.append('action', action);
        if(showAlert){
            const confirmationMessage = await Swal.fire({
                title: "Are you sure?",
                text: `Do you want to remove this?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#dc3545',
                confirmButtonText: "Yes, Remove it!",
                cancelButtonText: "Cancel",
                dangerMode: true,
            });
            if(confirmationMessage.isConfirmed) {
                setLoading(true);
                try {
                    const response = await removeBillingDetailReq(formData);
                    if (response.status.toLowerCase() === 'success') {
                        await getBillingDetail();
                        customAlert(response.result, 'success');
                        return response;
                    } else {
                        customAlert(response.result, 'error');
                        return response;
                    }
                } catch (error) {
                    console.error('Error getting billing detail:', error);
                } finally {
                    setLoading(false);
                }
            }
        } else {
            setLoading(true);
            try {
                const response = await removeBillingDetailReq(formData);
                if (response.status.toLowerCase() === 'success') {
                    await getBillingDetail();
                    customAlert(response.result, 'success');
                    return response;
                } else {
                    customAlert(response.result, 'error');
                    return response;
                }
            } catch (error) {
                console.error('Error getting billing detail:', error);
            } finally {
                setLoading(false);
            }
        }

    };
    //update logins from platform module
    const updateLogins = async (data) => {
        setLoading(true);
        try {
            const response = await updateBillingLoginReq(data);
            if (response.status.toLowerCase() === 'success') {
                await getBillingDetail();
                customAlert(response.result, 'success');
                return true;
            } else {
                customAlert(response.result, 'error');
                return false;
            }
        } catch (error) {
            console.error('Error updating billing detail:', error);
        } finally {
            setLoading(false);
        }
    }

    const saveProviderDetail = async (data) => {
        setLoading(true);
        try {
            const response = await addProviderDetailReq(data);
            if (response?.status?.toLowerCase() === 'success') {
                customAlert(response?.message, 'success');
                backHome();
                await getBillingDetail();
                return true;
            } else {
                customAlert(response?.message, 'error');
                return false;
            }
        } catch (error) {
            console.error('Error adding billing detail:', error);
            return false;
        } finally {
            setLoading(false);
        }
    }


    //updateBillingDetail
    const updateBillingPracticeDetail = async (data) => {
        setLoading(true);
        try {
            const response = await updateBillingPracticeDetailReq(data);
            if (response?.status?.toLowerCase() === 'success') {
                await customAlert(response?.message, 'success');
                backHome();
                await getBillingDetail();
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error adding billing detail:', error);
            return false;
        } finally {
            setLoading(false);
        }
    }

    // getBillingUserList  billing sub users work start from here
    const getBillingUserList = async (userId) => {
        setLoading(true);
        try {
            const response = await getBillingUserListReq(userId);
            if (response?.status?.toLowerCase() === 'success') {
                setBillingUserList(response?.result);
                setFormNameForUser(null);
            } else {
                setBillingUserList([]);
            }
        } catch (error) {
            console.error('Error getting billing detail:', error);
            setBillingUserList([]);
        } finally {
            setLoading(false);
        }
    };

    //addBillingUser
    const addBillingUser = async (data) => {
        setLoading(true);
        try {
            const response = await addBillingUserReq(data);
            if (response?.status?.toLowerCase() === 'success') {
                await getBillingUserList(data?.parent_id);
                customAlert(response?.result, 'success');
            } else {
                customAlert(response?.result, 'error');
            }
        } catch (error) {
            customAlert(`Error adding billing detail: ${error}`, error);
            console.error('Error adding billing detail:', error);
        } finally {
            setLoading(false);
        }
    }

    //handleEditBillingSubUser
    const handleEditBillingSubUser = async (item, dispatchBy) => {
        setLoading(true);
        if(dispatchBy === 'edit'){
            setFormContextForUser(item);
            setFormNameForUser(dispatchBy);
        }else{
            setFormContextForUser(null);
            setFormNameForUser(dispatchBy);
        }

        setLoading(false);
    }
    //handleRemoveBillingSubUser
    const removeBillingSubUser = async (item) => {
        setLoading(true);
        try {
            const response = await removeBillingSubUserReq(item);
            if (response?.status?.toLowerCase() === 'success') {
                setFormNameForUser(null);
                await getBillingUserList(item?.parent_id);
                customAlert(response?.result, 'success');
            } else {
               customAlert(`${response?.result} !, please try again`, 'error');
            }
        } catch (error) {
            customAlert('Error adding billing detail:', error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <BillingContext.Provider value={{isLoading: loading, backHome, saveLoginDetails, getBillingDetail,editBillingDetail, formContext, formName, billingDetail, removeBillingDetail, updateLogins, saveProviderDetail, billingLists, updateBillingPracticeDetail, addBillingUser, getBillingUserList, billingUserList, handleEditBillingSubUser, formContextForUser, formNameForUser, removeBillingSubUser}}>
            {children}
        </BillingContext.Provider>
    )
}
