import React, {useContext, useEffect} from "react";
import {HeaderTitle} from "../../general/HeaderTitle";
import {BillingUserList} from "./BillingUserList";
import {AddBillingUser} from "./AddBillingUser";
import {AuthContext} from "../../login/context/AuthContext";
import {BillingContext} from "../context/BillingContext";

export const AddUserWrapper = () => {
    let {auth, user} = useContext(AuthContext);
    const {isLoading, getBillingUserList, billingUserList, formNameForUser, backHome } = useContext(BillingContext);
    useEffect(() => {
        if(user?.user_id){
            getBillingUserList(user?.user_id);
        }
    }, []);
let lists = [];

console.log('auth', auth)
console.log('user', user)
console.log('billingUserList', billingUserList)
    return (
        <>
            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="col-12">
                        <HeaderTitle title="Billing Details" dispatchBy="billingDetail" back={backHome} backToModule="addUser" />
                    </div>
                </div>
                {(isLoading) && (
                    <div className="loadingOverLay">
                        <h4>Processing! Please Wait...</h4>
                    </div>
                )}
                { billingUserList.length > 0 && formNameForUser == null ? <BillingUserList lists={billingUserList} /> : <AddBillingUser /> }
            </div>
        </>
    )
}
